<template>
  <div>
    <v-autocomplete
      @change="dataChanged()"
      :items="schools"
      :search-input.sync="skillsInput"
      :rules="required ? [rules.required] : []"
      :tabindex="tabindex ? tabindex : ''"
      :hide-details="hideDetails"
      :item-text="(item) => `${item.Name} (${item.Suburb}, ${item.State})`"
      item-value="Name"
      v-model="input"
      :label="label"
      deletable-chips
      :dense="dense"
      ref="field"
      small-chips
      multiple
      outlined
      chips
    ></v-autocomplete>
  </div>
</template>

<script>
import rules from "@/rules"
import schools from '@/assets/json/schools.json'

export default {
  props: {
    required: Boolean,
    multiple: Boolean,
    label: String,
    value: [String, Array],
    tabindex: Number,
    hideDetails: Boolean,
    dense: Boolean
  },

  data() {
    return {
      rules,
      schools,
      input: this.value,
      skillsInput: null,
    }
  },

  methods: {
    updateField() {
      this.$emit("input", this.input)
    },

    dataChanged() {
      this.$emit("update:value", this.input)
      this.skillsInput = null
    },
  },
}
</script>